<template>
  <div>
    <b-container class="mb-5">
      <b-row>
        <b-col>
          <b-alert v-if="showError" variant="danger" show>Not all data is filled in</b-alert>
          <h5>Title:</h5>
          <b-input v-model="dataRequest.title"/>
        </b-col>
      </b-row>

      <b-row v-if="!dataRequest.id" class="mt-3">
        <b-col>
          <h5>Upload logo:</h5>
          <b-form-file
              id="file-input"
              v-model="dataRequest.logo"
          ></b-form-file>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <h5>Description:</h5>
          <b-textarea v-model="dataRequest.description"/>
        </b-col>
      </b-row>

    </b-container>
    <div class="filters-group mb-4">
      <div class="form-floating d-flex">
        <select
            class="form-select my-auto"
            id="floatingSelect"
            aria-label="Floating label select example"
            @change="handleSelectChange($event)"
        >
          <option value="4" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">title</option>
          <option value="5" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">subtitle</option>
          <option selected value="6" :class="{ 'is-active': editor.isActive('paragraph') }">text</option>

        </select>

      </div>

      <input
          class="my-auto"
          type="color"
          @input="editor.chain().focus().setColor($event.target.value).run()"
          :value="editor.getAttributes('textStyle').color"
      >
      <div class="editor-group">
        <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
          <img alt="" src="@/assets/icon/icons-editor/format_bold_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button @click="editor.chain().focus().toggleItalic().run()"
                :class="{ 'is-active': editor.isActive('italic') }">
          <img alt="" src="@/assets/icon/icons-editor/format_italic_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button @click="editor.chain().focus().toggleStrike().run()"
                :class="{ 'is-active': editor.isActive('strike') }">
          <img alt="" src="@/assets/icon/icons-editor/format_strikethrough_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button @click="editor.chain().focus().toggleUnderline().run()"
                :class="{ 'is-active': editor.isActive('underline') }">
          <img alt="" src="@/assets/icon/icons-editor/format_underlined_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button @click="editor.chain().focus().setTextAlign('left').run()"
                :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
          <img alt="" src="@/assets/icon/icons-editor/format_align_left_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button @click="editor.chain().focus().setTextAlign('right').run()"
                :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
          <img alt="" src="@/assets/icon/icons-editor/format_align_right_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button @click="editor.chain().focus().setTextAlign('center').run()"
                :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
          <img alt="" src="@/assets/icon/icons-editor/format_align_center_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button @click="editor.chain().focus().setTextAlign('justify').run()"
                :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
          <img alt="" src="@/assets/icon/icons-editor/format_align_justify_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <button @click="openLinkDialog">
          <img alt="" src="@/assets/icon/icons-editor/link_FILL0_wght400_GRAD0_opsz24.svg">
        </button>
        <div class="position-relative">
          <button @click="toggleDropList('bulleted')">
            <img alt="" src="@/assets/icon/icons-editor/format_list_bulleted_FILL0_wght400_GRAD0_opsz24.svg">
          </button>
          <div v-if="isOpenBulleted" class="drop-list">
            <button @click="editor.chain().focus().toggleBulletList().run()"
                    :class="{ 'is-active': editor.isActive('bulletList') }">
              toggleBulletList
            </button>
            <button @click="editor.chain().focus().splitListItem('listItem').run()"
                    :disabled="!editor.can().splitListItem('listItem')">
              splitListItem
            </button>
            <button @click="editor.chain().focus().sinkListItem('listItem').run()"
                    :disabled="!editor.can().sinkListItem('listItem')">
              sinkListItem
            </button>
            <button @click="editor.chain().focus().liftListItem('listItem').run()"
                    :disabled="!editor.can().liftListItem('listItem')">
              liftListItem
            </button>
          </div>
        </div>
        <div class="position-relative">
          <button @click="toggleDropList('number')">
            <img alt="" src="@/assets/icon/icons-editor/format_list_numbered_FILL0_wght400_GRAD0_opsz24.svg">
          </button>
          <div v-if="isOpenNumber" class="drop-list">
            <button @click="editor.chain().focus().toggleOrderedList().run()"
                    :class="{ 'is-active': editor.isActive('orderedList') }">
              toggleOrderedList
            </button>
            <button @click="editor.chain().focus().splitListItem('listItem').run()"
                    :disabled="!editor.can().splitListItem('listItem')">
              splitListItem
            </button>
            <button @click="editor.chain().focus().sinkListItem('listItem').run()"
                    :disabled="!editor.can().sinkListItem('listItem')">
              sinkListItem
            </button>
            <button @click="editor.chain().focus().liftListItem('listItem').run()"
                    :disabled="!editor.can().liftListItem('listItem')">
              liftListItem
            </button>
          </div>
        </div>
        <button class="add-image" @click="addImage">
          <img alt="" src="@/assets/icon/icons-editor/add_a_photo_FILL0_wght400_GRAD0_opsz24.svg">
          add image
        </button>

      </div>
    </div>
    <editor-content v-model="text" :editor="editor"/>
    <b-container class="mt-5">
      <b-row>
        <b-col class="text-center">
          <b-button v-if="!dataRequest.id" class="w-25" @click="Create" variant="success">Create</b-button>
          <b-button v-else class="w-25" @click="Update" variant="info">Update</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {Editor, EditorContent, BubbleMenu} from "@tiptap/vue-2";
import Heading from "@tiptap/extension-heading";
import Paragraph from "@tiptap/extension-paragraph";
import TextStyle from '@tiptap/extension-text-style';
import Text from '@tiptap/extension-text';
import {StarterKit} from "@tiptap/starter-kit";
import {Color} from '@tiptap/extension-color';
import {Underline} from '@tiptap/extension-underline';
import {TextAlign} from '@tiptap/extension-text-align';
import {Link} from '@tiptap/extension-link';
import {ListItem} from '@tiptap/extension-list-item';
import {BulletList} from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import {HardBreak} from '@tiptap/extension-hard-break';
import Document from '@tiptap/extension-document'
import Dropcursor from '@tiptap/extension-dropcursor'
import Image from '@tiptap/extension-image'
import api from "@/services/api/api";

export default {
  name: 'CreateNews',
  props:["news"],
  components: {
    EditorContent,
    BubbleMenu
  },
  data() {
    return {
      editor: null,
      text: "",
      isOpenNumber: false,
      isOpenBulleted: false,
      dataRequest: this.news,
      showError: false
      // selectedColor: '#000000'
    }
  },

  mounted() {
    this.editor = new Editor({
      content: this.news.body,

      extensions: [
        StarterKit,
        Heading.configure({
          levels: [4, 5],
          HTMLAttributes: {
            class: 'test'
          }
        }),
        Document,
        Paragraph.configure({
          HTMLAttributes: {
            class: 'text-news',
          },
        }),
        Text,
        TextStyle,
        Color,
        Underline,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Link,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
        Document,
        Image,
        Dropcursor,
        // Image.configure({
        //   align: 'center',
        // }),
      ],
      onUpdate: () => {
        this.dataRequest.body = this.editor.getHTML();
      }
    })
  },

  methods: {

    Update(){
      if (!this.validateBeforeCreate()) {
        this.showError = true
        return
      }
      api.updateNews(this.dataRequest.id, this.dataRequest)
          .then(()=>{
            window.location.href = '/admin/news';
          })

    },

    validateBeforeCreate() {
      if (!this.dataRequest.title || !this.dataRequest.logo || !this.dataRequest.description) {
        return false;
      }
      return true;
    },

    Create() {
      if (!this.validateBeforeCreate()) {
        this.showError = true
        return
      }
        api.addNews(this.dataRequest)
            .then(()=>{
              window.location.href = '/admin/news';
            })

    },
    handleSelectChange(event) {
      const selectedValue = event.target.value;

      switch (selectedValue) {
        case '4':
          this.editor.chain().focus().toggleHeading({level: 4}).run();
          break;
        case '5':
          this.editor.chain().focus().toggleHeading({level: 5}).run();
          break;
        case '6':
          this.editor.chain().focus().setParagraph().run();
          break;
        default:
          break;
      }
    },
    openLinkDialog() {
      const url = prompt('Enter the URL:');

      if (url) {
        this.editor.chain().focus().setLink({href: url}).run();
      }
    },
    addRedLine() {
      console.log('Adding red line');
      this.editor.chain().focus().setParagraph().updateAttributes({class: 'red-line'}).run();
    },

    toggleDropList(type) {
      if (type === 'bulleted') {
        console.log('test1')
        this.isOpenBulleted = !this.isOpenBulleted;
        this.isOpenNumber = false; // Закрыть другой выпадающий список
      } else if (type === 'number') {
        console.log('test2')
        this.isOpenNumber = !this.isOpenNumber;
        this.isOpenBulleted = false; // Закрыть другой выпадающий список
      }
    },
    // addImage() {
    //   const url = window.prompt('URL')
    //
    //   if (url) {
    //     this.editor.chain().focus().setImage({ src: url}).run()
    //   }
    // },

    addImage() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.click();

      input.addEventListener('change', async () => {
        const file = input.files[0];
        if (!file) return;

        if (!file.type.startsWith('image/')) {
          alert('The file is not a valid image.');
          return;
        }

        const formData = new FormData();
        formData.append('file', file);

        api.uploadImage(formData)
            .then((response)=>{
              const imageUrl = process.env.VUE_APP_API_URL+response.data; // replace with actual path to URL in your API response
              this.editor.chain().focus().setImage({ src: imageUrl }).run();
            })
      });
    },
  },


  beforeDestroy() {
    this.editor.destroy()
  },

}
</script>

<style scoped lang="scss">

::v-deep{
  .tiptap {
    min-height: 400px;
    border: 1px solid gray;
    border-radius: 7px;
    overflow-y: auto;
    padding: 30px;
    img {
      max-width: 100%;
    }
  }
}

.btn-save{
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background: #71e36d;
}
.filters-group {
  display: flex;
  //grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  .form-select {
    padding: 0.3rem 2rem 0.3rem 0.3rem;
    height: fit-content;
    min-height: fit-content;
  }

}

.btn-link {

}

.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;

}

.is-active {
  background: #e1e1e1 !important;
  padding: 0.3rem;
  border-radius: 50%;

}

.red-line {
  text-indent: 1.5em;
}

.test {
  color: red;
}

.editor-group {
  display: flex;
  gap: 1rem;
  height: fit-content;

  button {
    background: none;
    border: none;
    outline: none;
    padding: 0.3rem;
    border-radius: 50%;
    position: relative;
  }

  .drop-list {
    position: absolute;
    top: 100%;
    left: 0;
    transform: translate(0, 0);
    padding: 0.5rem;
    background: #FFFFFF;
    z-index: 9;
    border-radius: 4px;

    .is-active {
      border-radius: 4px;
    }

  }

  .add-image{
    background: #e1e1e1;
    border-radius: 4px;
  }
}
</style>