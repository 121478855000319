<template>
  <div class="box-login mb-6">
    <p class="title-form">
      Верификация
    </p>
    <div class="mx-auto flex mb-2">
      <img class="m-auto" alt="" src="../../assets/icon/icon_checkmark-done-circle-sharp.svg">
      <p class="ms-2 my-auto message-success">
        Новый пароль установлен
      </p>
    </div>
    <ButtonMain :button-title="buttonMain"></ButtonMain>
  </div>
</template>

<script>

import ButtonMain from "@/components/shared/ButtonMain.vue";

export default {
  name: 'PasswordRecoverySuccess',
  components: {ButtonMain},
  data() {
    return{
      buttonMain: "Войти",
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/color";

.box-login {
  gap: 1.5rem;

  .title-form {
    margin: 0;
  }

  .message-success {
    color: $dark-green;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
}
</style>