import apiUser from "@/services/api/api"
import Cookies from 'js-cookie';
import store from "@/store";
import user from "@/services/api/api";
import api from "@/services/api/api";
import jwtDecode from "jwt-decode";
import router from "@/router";


const authUser = async (data) => {
    try {
        const response = await apiUser.auth(data);

        if (response.data.TempToken) {
            Cookies.set('TempToken', response.data.TempToken);
            return "2fa";
        } else {
            Cookies.set('token', response.data.token, { expires: new Date(new Date().getTime() + 15 * 60 * 1000) });
            Cookies.set('refreshToken', response.data.refreshToken, { expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) } );
            let decodedToken = jwtDecode(response.data.token)
            store.commit("setUser", decodedToken)

            return "true";
        }
    } catch (error) {
        if (error.response) {
            return "false";
        }
    }
};


const sendOTP = async (data) => {
    let payload = {
        "auth_code": data,
        "temp_token": Cookies.get("TempToken")
    }
    try {
        let response = await api.otpCheck(payload)
        Cookies.set('token', response.data.token, { expires: new Date(new Date().getTime() + 15 * 60 * 1000) });
        Cookies.set('refreshToken', response.data.refreshToken, { expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) } );
        Cookies.remove("TempToken")

        let decodedToken = jwtDecode(response.data.token)
        store.commit("setUser", decodedToken)
        return "true"

    } catch (error) {
        return "false"
    }
}

const refreshJWT = () => {
    api.refreshJWT()
        .then((response)=>{
            Cookies.set('token', response.data.token, { expires: new Date(new Date().getTime() + 15 * 60 * 1000) });
            Cookies.set('refreshToken', response.data.refreshToken, { expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) } );

            let decodedToken = jwtDecode(response.data.token)
            store.commit("setUser", decodedToken)
            if (!router.currentRoute.path.startsWith('/admin')) {
                router.push('/admin');
            }
        })
        .catch(()=>{
            if (router.currentRoute.path !== '/auth') {
                router.push('/auth');
            }

        })

}

const exit = () => {
    Cookies.remove('token')
    Cookies.remove('refreshToken')
    window.location.href = "/"
}

export default {
    authUser,
    sendOTP,
    exit,
    refreshJWT
}