<template>
  <div class="box-login mb-6">
    <p class="title-form">
      Восстановление пароля
    </p>
    <p class="description mb-4">
      Введите email, на который был зарегистрирован<br>
      аккаунт и мы отправим вам код подтверждения.
    </p>
    <InputMain class="mb-6" @input-changed="updateInputValue" :type="`email`" :inputLabel="inputLabel"
               :src="require('@/assets/icon/alternate_email.svg')"></InputMain>
    <ButtonMain class="mb-4" :buttonTitle="buttonTitle" @click="nextForm"></ButtonMain>
    <div class="tabs-group">
      <div class="tab-active"></div>
      <div class="tab-inactive"></div>
      <div class="tab-inactive"></div>
    </div>
  </div>
</template>

<script>
import InputMain from "@/components/shared/InputMain.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";

export default {
  name: 'FormPasswordRecovery',
  components: {InputMain, ButtonMain},
  data() {
    return {
      buttonTitle: "Продолжить",
      inputLabel: "E-mail",
    }
  },
  methods: {
    updateInputValue(newValue) {
      this.inputFilled = newValue.trim() !== '';
    },
    nextForm() {
      if (this.inputFilled) {
        this.$emit('next');
      } else {
        alert('Инпут не заполнен')
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/color";

.box-login {
  gap: 0.5rem;

  .title-form {
    margin: 0;
  }

  .description {
    color: $gray-blue-input-text;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  .link {
    color: #1D1A31;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    text-decoration-line: underline;
    cursor: pointer;
    width: fit-content;
  }

  .tabs-group {
    display: flex;
    margin: 0 auto;
    gap: 1rem;

    .tab-active {
      width: 3rem;
      height: 0.25rem;
      border-radius: 2px;
      background-color: #53AB7B;
    }

    .tab-inactive {
      @extend .tab-active;
      background-color: #D4E1EE;
    }
  }
}
</style>