import { render, staticRenderFns } from "./ModalDelete.vue?vue&type=template&id=365b4ff6&scoped=true"
import script from "./ModalDelete.vue?vue&type=script&lang=js"
export * from "./ModalDelete.vue?vue&type=script&lang=js"
import style0 from "./ModalDelete.vue?vue&type=style&index=0&id=365b4ff6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "365b4ff6",
  null
  
)

export default component.exports