<template>
  <div class="container-verification">
    <form class="input-container" @submit.prevent="submitForm">
      <input
          oninput="this.value = this.value.replace(/[^0-9]/g, '')"
          class="digit-input"
          v-for="(input, index) in inputs"
          :key="index"
          ref="inputFields"
          v-model="input.value"
          @input="handleInput(index)"
          @keydown="handleKeydown(index, $event)"
          :maxlength="1"
      />
    </form>
  </div>
</template>

<script>
export default {
  name: 'InputVerification',
  data() {
    return {
      inputs: [],
    };
  },
  methods: {
    handleInput(index) {
      if (this.inputs[index].value !== "") {
        if (index < this.inputs.length - 1) {
          this.$refs.inputFields[index + 1].focus();
        }
      }
    },
    handleKeydown(index, event) {
      if (event.key === "Backspace" && index > 0 && this.inputs[index].value === "") {
        this.$refs.inputFields[index - 1].focus();
      }
    },
    submitForm() {
      const values = this.inputs.map(input => input.value);
      console.log("Submitted values:", values);
    },
  },
  mounted() {
    this.inputs = Array(6).fill("").map(() => ({ value: "" }));
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/color.scss";
.container-verification {
  max-width: fit-content;
  .input-container {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    .digit-input {
      width: 48px;
      height: 48px;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      border: 0;
      border-bottom: 1px solid $text;
      outline: none;
      color: $text;
    }
  }
}
</style>