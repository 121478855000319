import axios from 'axios';
import Cookies from "js-cookie";
import router from "@/router";
import jwtDecode from "jwt-decode";
import store from "@/store";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.defaults.headers.common['Authorization'] = Cookies.get("token");
axios.defaults.headers.common['Refresh-Token'] = Cookies.get("refreshToken");

axios.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                axios.defaults.headers.common['Authorization'] = Cookies.get("token");
                axios.defaults.headers.common['Refresh-Token'] = Cookies.get("refreshToken");
                let response = await axios.get('/user/auth/refresh');

                Cookies.set('token', response.data.token, { expires: new Date(new Date().getTime() + 15 * 60 * 1000) });
                Cookies.set('refreshToken', response.data.refreshToken, { expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000) });

                originalRequest.headers['Authorization'] = response.data.token;

                let decodedToken = jwtDecode(response.data.token)
                store.commit("setUser", decodedToken)

                return axios(originalRequest);
            } catch (refreshError) {
                if (router.currentRoute.path !== '/auth') {
                    router.push('/auth');
                }
            }
        }

        return Promise.reject(error);
    }
);


const auth = (data) => {
    return axios.post('/user/auth', data)
}

const refreshJWT = (data) => {
    return axios.get("/user/auth/refresh")
}

const checkAuth = (data) => {
    return axios.get("/user/auth")
}

const otpCheck = (data) => {
    return axios.post("/user/totp", data)
}


const newsPreview = () => {
    return axios.get('/news')
}

const changePassword = (newPassword) => {
    return axios.post('/user/change_password', `password=${newPassword}`, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
};

const generateTOTP = () => {
    return axios.get('/user/2fa')
}

const getUsers = () => {
    return axios.get("/users")
}

const createUser = (data) => {
    return axios.post('/user', data)
}

const deleteUser = (data) => {
    return axios.delete('/user/'+data)
}

const deleteNews = (data) => {
    return axios.delete('/news/'+data)
}

const getFullNews = (data)=> {
    console.log(data)
    return axios.get("/news_by_id/"+data)
}


const addNews = (data) => {
    const formData = new FormData();
    formData.append('logo', data.logo);
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('body', data.body);

    return axios.post('/news', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

const uploadImage = (data)=>{
    return axios.post('/file/upload', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

const updateNews = (id, updatedNews) => {
    return axios.put(`/news/${id}`, updatedNews)
};

export default {
    auth,
    checkAuth,
    refreshJWT,
    newsPreview,
    otpCheck,
    changePassword,
    generateTOTP,
    getUsers,
    createUser,
    deleteUser,
    deleteNews,
    addNews,
    uploadImage,
    getFullNews,
    updateNews
}
