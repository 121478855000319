<template>
  <div class="box-login mb-6" @keyup.enter="auth">
    <p class="title-form">
      Login <br>
    </p>

    <InputMain v-model="authData.Email" :type="`email`" :inputLabel="inputLogin" :src="require('@/assets/icon/alternate_email.svg')"></InputMain>
    <InputMain v-model="authData.Password" :type="`password`" :inputLabel="inputLPassword" :src="require('@/assets/icon/vpn_key.svg')"></InputMain>
    <ButtonMain @click="auth" class="mb-2" :button-title="buttonTitle"></ButtonMain>
    <p v-if="failed" style="text-align: center; font-size: 16px; color: red">
      Failed login
    </p>

  </div>
</template>

<script>
import ButtonGoogle from "@/components/shared/ButtonGoogle.vue";
import InputMain from "@/components/shared/InputMain.vue";
import InputCheckbox from "@/components/shared/InputCheckbox.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import serviceAuth from "@/services/auth"

export default {
  name: 'FormAuth',
  components: {ButtonMain, InputCheckbox, InputMain, ButtonGoogle},

  data() {
    return {
      buttonTitle: "Enter",
      inputLogin: "E-mail",
      inputLPassword: "Password",
      failed: false,
      authData: {
        Email: "",
        Password: ""
      }
    }
  },
  methods: {
    auth() {
      serviceAuth.authUser(this.authData)
          .then((response)=>{
            console.log(response)
            if (response === "false") {
              this.failed = true
            }
            else {
              this.$emit("success-auth", response)
            }

          })

    }
  }
}
</script>

<style scoped lang="scss">

.box-login {
  gap: 1rem;

  .link-password-recovery {
    color: #1D1A31;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    text-decoration-line: underline;
    cursor: pointer;
    width: fit-content;
    margin: 0 auto;
  }
}
</style>