<template>
  <div id="app">
    <div v-if="isAdminRoute" class="container-home" :class="{'has-sidebar': isAdminRoute}">
      <sidebar-menu :collapsed="true" :menu="menu" @toggle-collapse="toggleSidebar" />
      <router-view :class="{'mt-5': isAdminRoute, 'sidebar-open': sidebarOpen}"/>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NavMenu from "@/components/nav-menu/NavMenu.vue";
import serviceAuth from "@/services/auth"
import { SidebarMenu } from 'vue-sidebar-menu'
import api from "@/services/api/api";
import router from "@/router";
import jwtDecode from "jwt-decode";
import store from "@/store";
import Cookies from "js-cookie";

export default {
  name: 'App',
  components: {NavMenu, SidebarMenu},
  computed: {
    isAdminRoute() {
      return this.$route.path.startsWith('/admin');
    },
  },
  data() {
    return {
      sidebarOpen: false,
      currentComponent: null,
      treeData: [
        {
          name: 'Account ', children: [], isRoot: true, route: 'account'
        },
        {
          name: 'User management', children: [], isRoot: true, route: 'users'
        },
        {
          name: 'News', children: [], isRoot: true, route: 'news'
        }
      ],
      drops: {
        name: 'Writers & Reviewers'
      },
      menu: [
        {
          header: true,
          title: 'Main Navigation',
          hiddenOnCollapse: true
        },
        {
          href: '/admin/',
          title: 'User',
          icon: 'fa fa-user'
        },
        {
          href: '/admin/users',
          title: 'Users',
          icon: 'fa fa-users',
        },
        {
          href: "/admin/news",
          title: 'News',
          icon: 'fa fa-file'
        }
      ]
    };
  },
  methods: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    }
  },
  mounted() {
    serviceAuth.refreshJWT()
    // api.checkAuth()
    //     .then((response)=>{
    //
    //       store.commit("setUser", jwtDecode(Cookies.get('token')))
    //       if (router.currentRoute.path !== '/admin') {
    //         router.push('/admin');
    //       }
    //     })
    //     .catch((reject)=>{
    //
    //     })
  }
}


</script>

<style lang="scss">

.container-home.has-sidebar {
  display: flex;
  .sidebar-open {
    margin-left: 350px;  // Replace with actual width of sidebar
  }
}

.container-home {
  padding: 1rem;
  background: #F4F7FC;
  min-height: 100vh;
  display: flex;

  .nav-menu {
    flex: 1;
    border-radius: 48px;
    opacity: 0.98;
    background: #FFF;
    box-shadow: 0px -4px 16px 0px rgba(174, 201, 212, 0.50);
    padding: 2.5rem 2rem;
  }

  .title {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    margin: 0;
  }

  .content {
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 2.5rem;
  }
}

</style>
