<template>
  <div class="container-login">
    <div class="main-content">
      <FormPasswordRecovery v-if="activeFormIndex === 0" @next="nextForm" ></FormPasswordRecovery>
      <PasswordRecoveryVerification v-if="activeFormIndex === 1" @next="nextForm" @click="backForm"></PasswordRecoveryVerification>
      <FormNewPassword v-if="activeFormIndex === 2" @next="nextForm" @click="backForm"></FormNewPassword>
      <PasswordRecoverySuccess v-if="activeFormIndex === 3"></PasswordRecoverySuccess>
    </div>
  </div>
</template>

<script>
import PasswordRecoverySuccess from "@/components/authorization/PasswordRecoverySuccess.vue";
import PasswordRecoveryVerification from "@/components/authorization/PasswordRecoveryVerification.vue";
import FormNewPassword from "@/components/authorization/FormNewPassword.vue";
import FormPasswordRecovery from "@/components/authorization/FormPasswordRecovery.vue";

export default {
  name: "PagePasswordRecovery",
  components: {
    PasswordRecoverySuccess,
    PasswordRecoveryVerification, FormNewPassword, FormPasswordRecovery
  },
  data() {
    return {
      activeFormIndex: 0,
      forms: [FormPasswordRecovery, PasswordRecoveryVerification, FormNewPassword,  PasswordRecoverySuccess],
    }
  },
  methods: {
    nextForm() {
      if (this.activeFormIndex < this.forms.length) {
        this.activeFormIndex++;
      }
    },
    backForm() {
      if (this.activeFormIndex !== 0) {
        this.activeFormIndex--;
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/color";
</style>