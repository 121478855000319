<template>
  <ul :class="{'border-left': !isRoot, 'no-border': isRoot, 'no-border-level' : level === 2}">
    <li v-for="(item, index) in items" :key="index">
      <a class="link-article" @click="toggleItem(item)" :class="{ 'active' : item.expanded && level === 1}">
        <span v-if="level === 1" class="toggle-button" :class="{ 'd-none' : isLastLevel(item)}">
          {{ generateNumbering(index) }}&nbsp;
        </span>
        <span :class="{'marker' : level === 2}"></span>
        <span class="item-name" style="margin: 0" :class="{ 'first-item': isRoot === true && level === 0 }">
          <router-link :to="generateRoute(item)">{{ item.name }}</router-link>
        </span>
      </a>

      <NavMenu v-if="item.expanded && item.children && item.children.length" :items="item.children"
               :parentNumbering="generateNumbering(index)" :noNumbering="item.children.length === 0"
               :level="level + 1"/>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavMenu',
  props: {
    items: Array,
    parentNumbering: String,
    isRoot: Boolean,
    noNumbering: Boolean,
    level: Number,
  },
  methods: {
    generateNumbering(index) {
      if (this.parentNumbering && (!this.noNumbering || index !== this.items.length - 1)) {
        return `${this.parentNumbering}.${index + 1}`;
      } else {
        return this.noNumbering ? '' : `${index + 1}`;
      }
    },
    toggleItem(item) {
      if (item.children && item.children.length) {
        this.$set(item, 'expanded', !item.expanded);
      }
    },
    isLastLevel(item) {
      return !item.children || item.children.length === 0;
    },
    generateRoute(item) {
      return `/admin/${item.route}`;
    }
  },
}
</script>

<style scoped lang="scss">
.toggle-button {
  cursor: pointer;
  color: #A3AFC9;
}

.first-item {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  width: 100%;

  img {
    margin: auto 0 auto auto;
  }

}

.border-left {
  border-left: 2px solid #87a4e2; /* Добавляем border-left к элементам, кроме корневого */
  padding-left: 1rem;
}

.no-border-level {
  border: none;
}

.marker {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #a3afc9;
  position: absolute;
  top: 50%;
  left: -1.25rem;
  transform: translate(-50%, -50%);
}

li {
  list-style-type: none;
}

.d-none {
  display: none;
}

ul {
  margin-left: 1.5rem;
}

.no-border {
  padding: 0;
  margin: 0;
}

.link-article {
  display: flex;
  margin: 1rem 0;
  cursor: pointer;
  width: 100%;
  position: relative;

}

.link-article:hover {
  color: #3767CF;

  .marker {
    background: #3767CF;
  }
}

.active {
  text-decoration-line: underline;
  color: #3767CF;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;

  .toggle-button {
    color: #3767CF;
  }

}
</style>