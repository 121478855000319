<template>
  <b-container>
    <div class="section-title mb-4">
      <p class="title">
        New’s constructor
      </p>
      <div class="btn-group">
        <button-secondary :button-title="'Preview news'" @click="showComponentPreview"/>
        <button-secondary :button-title="'Create news'" @click="showComponentCreateNews"/>
      </div>
    </div>
    <b-card class="body-content">
      <component @edit-initial="editShow"
                 :news="newsForUpdate"
                 :is="currentComponent"></component>
    </b-card>

  </b-container>
</template>

<script>

import ButtonSecondary from "@/components/shared/ButtonSecondary.vue";
import PreviewNews from "@/components/PreviewNews.vue";
import CreateNews from "@/components/CreateNews.vue";
import api from "@/services/api/api";

export default {
  name: 'NewsPage',
  components: {ButtonSecondary},
  data() {
    return {
      currentComponent: null,
      newsForUpdate: {}
    }
  },

  mounted() {
    this.currentComponent = PreviewNews;
  },

  methods: {

    editShow(id){
      api.getFullNews(id)
          .then((response)=>{
            this.newsForUpdate = response.data
            this.currentComponent = CreateNews;
          })
    },

    showComponentPreview() {
      this.currentComponent = PreviewNews;
    },
    showComponentCreateNews() {
      this.currentComponent = CreateNews;
    },

  },

}
</script>

<style scoped lang="scss">


.section-title {
  display: flex;

  .btn-group {
    margin-left: auto;
    display: flex;
    gap: 1.5rem;
  }
}

</style>
