import { render, staticRenderFns } from "./ButtonGoogle.vue?vue&type=template&id=4ba0a3cd&scoped=true"
import script from "./ButtonGoogle.vue?vue&type=script&lang=js"
export * from "./ButtonGoogle.vue?vue&type=script&lang=js"
import style0 from "./ButtonGoogle.vue?vue&type=style&index=0&id=4ba0a3cd&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ba0a3cd",
  null
  
)

export default component.exports