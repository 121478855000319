<template>
  <b-container>
    <div class="section-title d-flex mb-4">
      <p class="title">
        User management
      </p>
      <div class="btn-group ms-auto me-0">
        <button-main :button-title="'Add user'" @click="showCreateUser"/>
      </div>
    </div>
    <b-card>
      <el-table v-if="currentComponent !== CreateUsers " :data="tableData" style="width: 100%">
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column label="Name" prop="username"></el-table-column>
        <el-table-column label="Email" prop="email"></el-table-column>
        <el-table-column label="2FA" prop="tfa">
          <template slot-scope="scope">
            <i v-if="scope.row.tfa" class="el-icon-check"></i>
            <i v-else class="el-icon-close"></i>
          </template>
        </el-table-column>
        <el-table-column label="Action" style="width: 10%">
          <template slot-scope="scope">
            <div class="btn-group">
              <!--            <button class="btn-action" @click="showCreateUser">-->
              <!--              <img alt="" src="@/assets/icon/edit_FILL0_wght400_GRAD0_opsz24.svg">-->
              <!--            </button>-->
              <button @click="confirmDelete(scope.row.id)" class="btn-action">
                <img alt="" src="@/assets/icon/delete_FILL0_wght400_GRAD0_opsz24.svg">
              </button>
            </div>
          </template>

        </el-table-column>
      </el-table>
      <component :is="currentComponent" @back-to-list="goBackToList"></component>
    </b-card>

    <b-modal ref="deleteModal" hide-footer hide-header-close title="Confirmation">
      <p class="my-4">Are you sure you want to delete the user?</p>
      <b-button class="me-4" variant="danger" @click="deleteUser">Delete</b-button>
      <b-button variant="secondary" @click="cancelDelete">Cancel</b-button>
    </b-modal>

  </b-container>
</template>

<script>
import ButtonSecondary from "@/components/shared/ButtonSecondary.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import PreviewNews from "@/components/PreviewNews.vue";
import CreateUsers from "@/components/CreateUsers.vue";
import ModalDelete from "@/components/shared/ModalDelete.vue";
import api from "@/services/api/api";

export default {
  name: 'UserManagement',
  computed: {
    CreateUsers() {
      return CreateUsers
    }
  },
  components: {ModalDelete, ButtonMain, ButtonSecondary},
  data() {
    return {
      tableData: [],
      currentComponent: null,
      userIdToDelete: null

    };
  },
  mounted() {
    // this.fetchDataFromBackend();
    api.getUsers()
        .then((response)=>{
          this.tableData = response.data
        })

  },
  methods: {
    showCreateUser() {
      this.currentComponent = CreateUsers;
    },
    goBackToList() {
      this.currentComponent = null;
    },
    confirmDelete(userId) {
      this.userIdToDelete = userId;
      this.$refs.deleteModal.show();
    },
    deleteUser() {
      api.deleteUser(this.userIdToDelete)
          .then(()=>{
            api.getUsers()
                .then((response)=>{
                  this.tableData = response.data
                })
          })
      this.$refs.deleteModal.hide();
    },
    cancelDelete() {
      this.$refs.deleteModal.hide();
    }
  },
}

</script>

<style scoped lang="scss">
.section-title {
  display: flex;

  .title {
    height: fit-content;
    margin: auto 0;
  }


}

.btn-group {
  margin: auto;
  display: flex;
  gap: 1.5rem;
  //justify-content: end;

  .btn-action {
    background: none;
    padding: 0.3rem;
    border: none;
    outline: none;
  }
}
</style>