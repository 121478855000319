<template>
  <div class="box-login mb-6">
    <p class="title-form">
      Верификация
    </p>
    <p class="description mb-6">
      На email mail*****@gmail.com<br>
      выслан код подтверждения.<br>
      Введите полученный код:
    </p>
    <InputVerification class="mb-5 mx-auto"></InputVerification>
    <ButtonMain :button-title="buttonMain" @click="nextForm" class="mb-2"></ButtonMain>
    <ButtonSecondary :button-title="buttonSecondary" @click="backForm" class="mb-6"></ButtonSecondary>
    <div class="tabs-group">
      <div class="tab-inactive"></div>
      <div class="tab-active"></div>
      <div class="tab-inactive"></div>
    </div>

  </div>
</template>

<script>
import ButtonMain from "@/components/shared/ButtonMain.vue";
import ButtonSecondary from "@/components/shared/ButtonSecondary.vue";
import InputVerification from "@/components/shared/InputVerification.vue";

export default {
  name: 'PasswordRecoveryVerification',
  components: {ButtonMain, InputVerification, ButtonSecondary},
  data() {
    return{
      buttonMain: "Продолжить",
      buttonSecondary: "Назад",

    }
  },
  methods: {
    nextForm() {
      this.$emit('next');
    },
    backForm() {
      this.$emit('click')
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/color";
.box-login{
  gap: 0.5rem;
  .tabs-group {
    display: flex;
    margin: 0 auto;
    gap: 1rem;

    .tab-active {
      width: 3rem;
      height: 0.25rem;
      border-radius: 2px;
      background-color: $dark-green;
    }

    .tab-inactive {
      @extend .tab-active;
      background-color: $light-blue-stroke;
    }
  }
}
</style>