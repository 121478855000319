<template>
  <b-container class="">
    <div class="section-title mb-4">
      <p class="title">
        Account
      </p>
      <div class="btn-group">
        <!--        <button-main :button-title="'Edit'"/>-->
        <button-secondary @click="exit" :button-title="'Exit'" :colorButton="red"/>
      </div>
    </div>
    <b-card>
      <b-container>
        <b-row>
          <b-col>
            <h3>
              {{ user.username }}
            </h3>
            <h4>
              {{ user.email }}
            </h4>
          </b-col>
        </b-row>
      </b-container>



        <b-container class="mt-5">
          <b-row>
            <b-col>
              <h5 class="mb-5">Changing password</h5>
              <b-form class="password-form mt-4" >
                <b-form-input v-model="password.new" type="password"  placeholder="Enter new password" class="mt-2"></b-form-input>
                <b-form-input v-model="password.repeat" type="password" placeholder="Repeat password" class="mt-2"></b-form-input>
                <b-button @click="changePassword" class="mt-4" variant="warning">Change</b-button>

                <b-alert variant="danger" class="mt-5" show v-if="badPassword">{{errMsg}}</b-alert>
                <b-alert variant="success" class="mt-5" show v-if="successPassword">The password has been changed</b-alert>
              </b-form>
            </b-col>
            <b-col>
              <h5 class="mb-5">Two-factor authentication</h5>
              <h5 v-if="!user.tfa" style="color: #eb4747;">
                Two-factor authentication is disabled. Enable it to increase security.
              </h5>
              <p v-if="user.tfa" class="text-success">
                Two-factor authentication enabled.
              </p>
              <b-button v-if="!user.tfa" @click="enable2FA" variant="success">Enable</b-button>

              <div v-if="QrCode">
                <h6 class="mt-1">Your OTP QrCode:</h6>
                <img :src="QrCode">

                <h6 class="mt-3">Secret code:</h6>
                <p>{{secretTOTP}}</p>
              </div>


            </b-col>
          </b-row>


        </b-container>

    </b-card>

  </b-container>
</template>

<script>
import ButtonSecondary from "@/components/shared/ButtonSecondary.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import serviceAuth from "@/services/auth"
import {mapState} from "vuex";
import api from "@/services/api/api";
export default {
  name: 'AccountPage',
  components: {ButtonMain, ButtonSecondary},
  data() {
    return {
      red: true,
      password: {
        new:"",
        repeat:""
      },
      showPassword: false,
      badPassword: false,
      successPassword: false,
      errMsg: "Bad passwords",
      secretTOTP: "",
      QrCode: null
    };
  },
  methods: {
    changePassword(){
      console.log("Test")
      if (this.password.new.length < 10) {
        this.errMsg = "Password must be at least 10 characters long";
        this.badPassword = true;
      } else if (this.password.new === this.password.repeat && this.password.new !== "") {
        api.changePassword(this.password.new)
            .then(()=>{
              this.successPassword = true
              this.badPassword = false
            })
            .catch((err)=>{
              console.log(err)
              this.badPassword = true
              this.errMsg = err.response.data.error
            })
      } else {
        this.errMsg = "Password mismatch";
        this.badPassword = true;
      }
    },
    enable2FA(){
      api.generateTOTP()
          .then((response) =>{
            this.secretTOTP = response.data.secret
            this.QrCode = `data:image/png;base64,${response.data.image}`
            serviceAuth.refreshJWT()
          })
    },
    exit() {
      serviceAuth.exit()
    }
  },

  computed: {
    ...mapState({
      user: state => state.user
    })
  }

}
</script>

<style scoped lang="scss">

.password-form {
  width: 300px;
}
.section-title {
  display: flex;

  .title {
    height: fit-content;
    margin: auto 0;
  }


  .btn-group {
    margin-left: auto;
    display: flex;
    gap: 1.5rem;
  }
}

.body-content {
  display: flex;
  flex-direction: column;

  .position-relative {
    width: fit-content;
  }

  .eye-icon {
    position: absolute;
    top: 50%;
    right: -10%;
    transform: translate(60%, -50%);
  }

  .btn-edit {
    border: none;
    background: transparent;
    outline: none;
    padding: 0.3rem;
    cursor: pointer;
    margin-left: 3rem;
  }

  .password {
    border: none;
    background: no-repeat;
    outline: none;
    margin: auto 0;
  }
}
</style>