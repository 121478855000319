import Vue from 'vue'
import VueRouter from 'vue-router'
import PageAuth from "@/views/PageAuth.vue";
import PagePasswordRecovery from "@/views/PagePasswordRecovery.vue";
import HomePage from "@/views/NewsPage.vue";
import CreateNews from "@/components/CreateNews.vue";
import AccountPage from "@/views/AccountPage.vue";
import NewsPage from "@/views/NewsPage.vue";
import UserManagement from "@/views/UserManagement.vue";
import Cookies from "js-cookie";

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    component: {
      render: (h) => h('router-view'),
    },
    beforeEnter: (to, from, next) => {
      let token = Cookies.get("token")
      let refreshToken = Cookies.get("refreshToken")
      next()

    },
    children: [
      {
        path: '/',
        name: 'auth',
        component: PageAuth,
      },
      // {
      //   path: 'password-recovery',
      //   name: 'password-recovery',
      //   component: PagePasswordRecovery,
      // },
    ],
  },
  {
    path: '/admin',
    component: {
      render: (h) => h('router-view'),
    },
    children: [
      {
        path: 'news',
        name: 'news-page',
        component: NewsPage,
      },
      {
        path: '/',
        name: 'account',
        component: AccountPage,
      },
      {
        path: 'users',
        name: 'users',
        component: UserManagement,
      }
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
