<template>
  <b-container>
    <b-row>
      <b-col  cols="6" v-for="(newsItem, index) in response" :key="newsItem.id">
        <div>
          <img alt=""  class="image-limited" :src="getImage(newsItem.logo)">
        </div>

        <div class="news-info mt-1">
          <p class="publication-date">
            <!--          {{ newsItem.created_at | formatDate }}-->
          </p>
          <p class="title">
            {{ newsItem.title }}
          </p>
          <p class="description">
            {{newsItem.description}}
          </p>
        </div>
        <div class="btn-group">
          <button-main class="blue-button" @click="editInitial(newsItem.id)" :buttonTitle="'Editing'"/>
          <button-secondary class="blue-button" @click="confirmDelete(newsItem.id)" :colorButton="red" :buttonTitle="'Delete'"/>
        </div>
      </b-col>
    </b-row>



    <b-modal ref="deleteModal" hide-footer hide-header-close title="Confirmation">
      <p class="my-4">Are you sure you want to delete news?</p>
      <b-button class="me-4" variant="danger" @click="deleteNews">Delete</b-button>
      <b-button variant="secondary" @click="cancelDelete">Cancel</b-button>
    </b-modal>

  </b-container>
</template>

<script>

import {defineComponent} from "vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import ButtonSecondary from "@/components/shared/ButtonSecondary.vue";
import apiNews from '@/services/api/api';
import api from "@/services/api/api";

export default defineComponent({
  name: 'PreviewNews',
  components: {ButtonSecondary, ButtonMain},
  data() {
    return {
      red: true,
      newsData: [],
      response: null,
      deleteId: null,
    };
  },

  mounted() {
    this.getNews()
  },
  methods: {
    editInitial(id){
      console.log(id)
      this.$emit("edit-initial",id)
    },
    getImage(url){
      return process.env.VUE_APP_API_URL+url
    },
    getNews() {
      apiNews.newsPreview()
          .then(response => {
            this.response = response.data;
          })
          .catch(error => {
            throw error;
          })
    },
    confirmDelete(newsId) {
      this.deleteId = newsId;
      this.$refs.deleteModal.show();
    },
    deleteNews() {
      api.deleteNews(this.deleteId)
          .then(()=>{
            apiNews.newsPreview()
                .then(response => {
                  this.response = response.data;
                })
                .catch(error => {
                  throw error;
                })
          })
      this.$refs.deleteModal.hide();
    },
    cancelDelete() {
      this.$refs.deleteModal.hide();
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY'); // Замените формат на нужный вам
    },
  },
})
</script>

<style scoped lang="scss">

.image-limited {
  max-width: 100%; // или нужный вам размер
  //max-height: 200px; // или нужный вам размер
  object-fit: cover;
}
.box-news {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem 1rem;

  .news {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    img {
      width: 100%;
      height: 100%;
    }

    .news-info {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      p {
        margin: 0;
      }

      .publication-date {
        color: #373737;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      .title {
        color: #184892;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }

      .description {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }


  }
}

.btn-group {
  display: flex;
  gap: 1.5rem;
}
</style>