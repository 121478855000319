<template>
  <div class="box-login mb-6">
    <p class="title-form mb-2">
      Новый пароль
    </p>
    <InputMain @input-changed="updateInputValuePassword" :type="`password`" :inputLabel="inputLabelPassword"
               :src="require('@/assets/icon/vpn_key.svg')"/>
    <InputMain @input-changed="updateInputValueConfirmation" :type="`password`" :inputLabel="inputLabelConfirmation"
               :src="require('@/assets/icon/vpn_key_alert.svg')"/>
    <ButtonMain @click="nextForm" :button-title="buttonTitleMain" class="mt-4 mb-2"/>
    <ButtonSecondary @click="backForm" :button-title="buttonTitleSecondary" class="mb-4"/>
    <div class="tabs-group">
      <div class="tab-inactive"></div>
      <div class="tab-inactive"></div>
      <div class="tab-active"></div>
    </div>
  </div>
</template>

<script>
import InputMain from "@/components/shared/InputMain.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import ButtonSecondary from "@/components/shared/ButtonSecondary.vue";

export default {
  name: 'FormNewPassword',
  components: {InputMain, ButtonMain, ButtonSecondary},
  data() {
    return {
      buttonTitleMain: "Сохранить изменения",
      buttonTitleSecondary: "Назад",
      inputLabelPassword: "Пароль",
      inputLabelConfirmation: "Подтверждение пароля",
    }
  },
  methods: {
    updateInputValuePassword(newValue) {
      this.inputFilledPassword = newValue.trim() !== '';
    },
    updateInputValueConfirmation(newValue) {
      this.inputFilledConfirmation = newValue.trim() !== '';
    },
    nextForm() {
      if (this.inputFilledPassword && this.inputFilledConfirmation) {
        this.$emit('next');
      } else {
        alert('Инпут не заполнен')
      }
    },
    backForm() {
      this.$emit('click')
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/color";

.box-login {
  gap: 1rem;

  .tabs-group {
    display: flex;
    margin: 0 auto;
    gap: 1rem;

    .tab-active {
      width: 3rem;
      height: 0.25rem;
      border-radius: 2px;
      background-color: $dark-green;
    }

    .tab-inactive {
      @extend .tab-active;
      background-color: $light-blue-stroke;
    }

  }
}
</style>