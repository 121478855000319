import { render, staticRenderFns } from "./PasswordRecoveryVerification.vue?vue&type=template&id=25963af0&scoped=true"
import script from "./PasswordRecoveryVerification.vue?vue&type=script&lang=js"
export * from "./PasswordRecoveryVerification.vue?vue&type=script&lang=js"
import style0 from "./PasswordRecoveryVerification.vue?vue&type=style&index=0&id=25963af0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25963af0",
  null
  
)

export default component.exports